<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col
        sm="6"
        cols="12"
        md="6"
        lg="3"
        v-if="currentCase !=undefined && currentCase.participantDetails!=undefined"
      >
        <router-link tag="a" to="/participant-management/current-participant">
          <material-stats-card
            color="#156a59"
            icon="mdi-account-card-details-outline"
            title="Current participant"
            v-bind:subText="currentCase.participantDetails.firstname + ' ' + currentCase.participantDetails.lastname"
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3">
        <material-stats-card
          @click="addCase"
          color="#156a59"
          icon="mdi-account-plus-outline"
          title="Add participant"
          value
        />
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3">
        <router-link tag="a" to="/participant-management/search">
          <material-stats-card
            color="#156a59"
            icon="mdi-account-search"
            title="Participant search"
            value
          />
        </router-link>
      </v-col>
    </v-row>
    
    <v-row><v-divider></v-divider>
    </v-row>
    <v-row v-if="recentCases !=undefined && recentCases.length>0">
     
      <v-card class="ml-3 mt-3">
        <v-card-title>
          My recent participants
        </v-card-title>
        <v-card-text>
        <v-data-table
          id="list-table"
          ref="datatable"
          :headers='[{ text: "Image", value: "avatar" },      { text: "Status", value: "status" },
              { text: "NDIS Number", value: "nDISNumber" },
              { text: "Participant first name", value: "firstName" },
              { text: "Participant last name", value: "lastName" },
              { text: "Email address", value: "eMail" },
              { text: "", value: "actions" }      
            ]'
          :items="recentCases"
          :loading="loading"
          item-key="id"
          :hide-default-footer="true"
      >
            <template slot="item" slot-scope="props">
              <tr>
                <td>
                  <v-avatar size="50px">
                    <v-img
                      v-if="props.item.participantDetails.photo"
                      v-bind:src="props.item.participantDetails.photo.downloadURL"
                      alt="Avatar"
                      aspect-ratio="1"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <v-icon
                      v-if="!props.item.participantDetails.photo"
                      color="green darken-2"
                      size="50"
                    >mdi-account-circle</v-icon>
                  </v-avatar>
                </td>
                <td text-xs-left>{{props.item.status}}</td>
                <td text-xs-left>{{props.item.participantDetails.nDISNumber}}</td>
                <td text-xs-left>{{props.item.participantDetails.firstname}}</td>
                <td text-xs-left>{{props.item.participantDetails.lastname}}</td>
                <td text-xs-left>{{props.item.participantDetails.email}}</td>
                <td text-xs-left>
                 
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon slot="activator" small class="mr-2" v-on="on" @click="editCase(props.item)">mdi-pencil</v-icon>
                    </template>
                    <span>Participant dashboard</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon slot="activator" small class="mr-2" v-on="on" @click="editCaseNotes(props.item)">mdi-calendar-text-outline</v-icon>
                    </template>
                    <span>General notes</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon slot="activator"  v-on="on" small class="mr-2" @click="editCaseGoals(props.item)">mdi-notebook</v-icon>
                    </template>
                    <span>Goals / Progress notes</span>
                  </v-tooltip>
                     <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon slot="activator"  v-on="on" small class="mr-2" @click="editCaseAlerts(props.item)">mdi-bell-alert</v-icon>
                    </template>
                    <span>Alerts</span>
                  </v-tooltip>
                  
                </td>
              </tr>
            </template>
          
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<style>
.v-card--material-stats:hover {
  border-top: 4px solid #156a59;
}
.v-data-table .v-icon:hover {color:#156a59}
</style>
<script>
export default {
  data() {
    return {
      loading: false,
    
    
      
    
     
     
    };
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    },
    recentCases(){
      return this.$store.getters.recentCases;
    }
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    addCase() {
      this.$store.commit("setCurrentCase", {});
      this.$router.push({ name: "Main-Form" });
    },
    editCase(data) {
      this.$store.commit("setCurrentCase", data);
      this.$router.push("/participant-management/current-participant");
    },
    editCaseNotes(data) {
      this.$store.commit("setCurrentCase", data);
      this.$router.push("/general-notes");
    },
    editCaseGoals(data) {
      this.$store.commit("setCurrentCase", data);
      this.$router.push("/goals");
    },
    editCaseAlerts(data) {
      this.$store.commit("setCurrentCase", data);
      this.$router.push("/participant-management/alerts");
    }
       
  }
};
</script>
